<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" align="center">
        <v-card class="elevation-12">
          <v-form ref="form" v-model="validForm" @submit.prevent="login()">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="security-form-title">
                Dashboard Sat Labs
                <div class="logo-container">
                  <img src="../../assets/images/logo.png" />
                </div>
              </v-toolbar-title>
            </v-toolbar>
            <div style="position: relative">
              <ProgressBar></ProgressBar>
            </div>
            <v-card-text v-if="!user.module_selection">
              <v-text-field
                label="E-mail"
                name="email"
                placeholder="Digite o e-mail de acesso"
                required
                :rules="[$rules.required]"
                v-model="info.email"
                prepend-icon="mdi-email"
                type="text"
              ></v-text-field>
              <v-text-field
                id="password"
                label="Senha"
                name="password"
                placeholder="Digite a senha de acesso"
                required
                v-model="info.password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[$rules.required]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-card-text>
            <v-card-text v-if="user.module_selection">
              <v-btn
                elevation="3"
                raised
                text
                class="mr-3 module-button"
                type="button"
                height="100px"
                width="100px"
                v-for="module in user.modules"
                :key="module.id"
                @click="selectModule(module.id)"
                :loading="loginButton.loading"
                ><div class="module-button-container">
                  <v-icon>{{ module.icon }}</v-icon>
                  {{ module.name }}
                </div>
              </v-btn>
            </v-card-text>
            <v-card-actions v-if="!user.module_selection">
              <v-spacer></v-spacer>
              <v-btn type="submit" color="primary">Entrar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
        <div class="ma-12">
          <a href="#/solicitar-nova-senha">Esqueci minha senha</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userApi from "@/common/api/user.api";
import toaster from "@/common/util/toaster";
import ProgressBar from "@/components/layout/ProgressBar";

export default {
  name: "Login",

  components: {
    ProgressBar,
  },

  data() {
    return {
      info: {},
      user: {},
      validForm: true,
      showPassword: false,
      loginButton: {
        loading: false,
      },
    };
  },

  methods: {
    login(module_id) {
      this.$refs.form.validate();

      if (this.validForm) {
        let data = {
          email: this.info.email,
          password: this.info.password,
        };

        if (module_id) {
          data.module_id = module_id;
        }

        this.loginButton.loading = true;

        userApi
          .login(data)
          .then((result) => {
            this.loginButton.loading = false;

            this.user = result.data;

            if (module_id || this.user.modules.length === 1) {
              userApi.setUserModule(module_id || this.user.modules[0].id);
            }

            if (!this.user.module_selection) {
              toaster.show("Olá, " + this.user.name + "!");
              this.$router.replace("/assinantes");
            }
          })
          .catch(() => (this.loginButton.loading = false));
      }
    },
    selectModule(module_id) {
      this.login(module_id);
    },
  },
};
</script>
<style scoped lang="scss">
.module-button {
  font-size: 11px;

  &-container {
    display: flex;
    flex-direction: column;
  }

  span {
    flex-direction: column;

    i {
      margin-bottom: 15px;
      font-size: 34px;
    }
  }
}
</style>
